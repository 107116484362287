import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IdentityServiceBase } from '@seahorse/auth';
import { NotificationService } from '@seahorse/common';
import { InvitationService } from '../services/invitation.service';
import { InvitationStatus } from '../models/invitation.model';

@Component({
  selector: 'cp-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class InvitationComponent implements OnInit, OnDestroy {
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);
  private _companyReferenceService = inject(InvitationService);
  private _identityService = inject(IdentityServiceBase);
  private _notificationService = inject(NotificationService);

  token: string;

  private _subscriptions = new Subscription();

  ngOnInit() {
    this._identityService.clearIdentity();

    this._subscriptions.add(
      this._route.queryParamMap
        .pipe(
          switchMap((params) => {
            this.token = params.get('key') || '';
            return this._companyReferenceService.verifyInvitation(this.token);
          })
        ).subscribe((res) => {
          if (!res.hasResult) {
            this._notificationService.showError('shared.terms.wrong');
            return;
          }

          switch (res.result) {
            case InvitationStatus.NotInvited:
              // show NotInvited error message
              break;
            case InvitationStatus.InvalidDomain:
              // show InvalidDomain error message
              break;
            case InvitationStatus.Valid:
              // show valid message
              this._router.navigate(['account/login'], {
                queryParams: { invitation: this.token },
              });
              break;
            default:
              this._notificationService.showError('shared.terms.wrong');
              break;
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
