import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPage } from './login/login.page';
import { SeahorseLayoutModule } from '@seahorse/layout';
import { SeahorseFormsModule } from '@seahorse/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { authRoutes } from './auth.routes';
import { SeahorseCommonModule } from '@seahorse/common';
import { ButtonComponent, LanguagePickerComponent, LoadingIndicatorComponent, SeahorseUiModule } from '@seahorse/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MfaSetupComponent } from '@seahorse/ui';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(authRoutes),
    TranslateModule,
    // seahorse
    SeahorseCommonModule,
    SeahorseLayoutModule,
    SeahorseFormsModule,
    SeahorseUiModule,
    MfaSetupComponent,
    LoadingIndicatorComponent,
    ButtonComponent,
    LanguagePickerComponent,
    ToastModule
  ],
  declarations: [LoginPage],
})
export class AuthModule {}
