import { Injectable } from '@angular/core';
import { AccountServiceBase, RegisterAccountModel } from '@seahorse/auth';
import { ResultStatus } from '@seahorse/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PortalAccountService extends AccountServiceBase {
  override registerAccount(
    model: RegisterAccountModel,
    invitation: string,
    languageCode?: string
  ) {
    let url = 'account/register';

    url = url + `?invitation=${invitation}`;

    if (languageCode) {
      url = url + `&languageCode=${languageCode}`;
    }

    return this.dataContext.post<any>(url, model);
  }

  override activateAccount(
    token: string,
    invitation: string,
  ) {
      const url = `account/activate?token=${token}&invitation=${invitation}`;
      return this.dataContext.get<any>(url).pipe(
        map((response) => {
          if (!response.hasResult || response.status !== ResultStatus.OK) {
            return false;
          }
  
          return true;
        })
      );
    }
}
