import { SeahorseRoute } from '@seahorse/common';
import { AppLayoutComponent } from './layout/components/app-layout.component';
import { authGuard } from '@seahorse/auth';

export const appRoutes: SeahorseRoute[] = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'profile',
        crumb: 'Profile',
        loadChildren: () =>
          import('./features/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'dashboard',
        crumb: 'Dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'users',
        crumb: 'Users',
        loadChildren: () =>
          import('./features/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'module',
        loadChildren: () =>
          import('./features/generic-ui/generic-ui.routes').then(
            (m) => m.GenericUiRoutesModule
          ),
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ],
  },
];
