import { inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type LoginForm = FormModel<typeof createLoginForm>;

export function createLoginForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    username: fb.control<string | null>(null, Validators.required),
    password: fb.control<string | null>(null, Validators.required),
    rememberMe: fb.nonNullable.control<boolean>(false),
  });
}
