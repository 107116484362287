import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { CompanyReferenceModel } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(private dataContext: DataContextService) {}

  verifyInvitation(token: string) {
    return this.dataContext.get<number>(
      `invitation/verify?key=${token}`
    );
  }

  acceptInvitation(invitation: string) {
    return this.dataContext.put<CompanyReferenceModel>(
      `invitation/accept?invitation=${invitation}`,
      null
    );
  }
}
