<sh-page-layout>
  <sh-header>
    <sh-language-picker position="right"></sh-language-picker>
  </sh-header>

  <div class="login row">
    <img src="../../../assets/shipm8-logo.svg" alt="{{'portal' | translate}}" />

    <div *ngIf="currentForm === formType.Login" class="form-wrapper">
      <form [formGroup]="loginForm">
        <sh-text-input
          formControlName="username"
          placeholder="{{'login.username' | translate}}"
        >
        </sh-text-input>
        <sh-password
          formControlName="password"
          placeholder="{{'login.password' | translate}}"
        >
        </sh-password>
        <sh-checkbox
          formControlName="rememberMe"
          placeholder="{{'login.keepMe' | translate}}"
        ></sh-checkbox>
        <sh-button (click)="login()">{{'login.text' | translate}}</sh-button>
        <sh-button link (click)="currentForm = formType.ForgotPassword">
          {{ 'account.login.forgotPassword' | translate }}
        </sh-button>
        <sh-button link (click)="currentForm = formType.Register">
          {{ 'account.registration.noAccount' | translate }}
        </sh-button>
      </form>
      <sh-message
        *ngIf="isUnauthorized"
        [content]="'Username or password is incorrect'"
        [severity]="'Error'"
      ></sh-message>
    </div>

    <div *ngIf="currentForm === formType.Register" class="form-wrapper">
      <form [formGroup]="registrationForm">
        <sh-text-input
          formControlName="firstName"
          placeholder="{{'account.register.firstName' | translate}}"
        >
        </sh-text-input>
        <sh-text-input
          formControlName="lastName"
          placeholder="{{'account.register.lastName' | translate}}"
        >
        </sh-text-input>
        <sh-password
          formControlName="password"
          placeholder="{{'account.register.password' | translate}}"
        >
        </sh-password>
        <sh-password
          formControlName="confirmPassword"
          placeholder="{{'account.register.confirmPassword' | translate}}"
        >
        </sh-password>
        <sh-text-input
          formControlName="companyName"
          placeholder="{{'account.register.company' | translate}}"
        >
        </sh-text-input>
        <sh-text-input
          formControlName="emailAddress"
          placeholder="{{'account.register.email' | translate}}"
        >
        </sh-text-input>
        <sh-checkbox
          formControlName="terms"
          placeholder="{{'account.register.iAgree' | translate}}"
        ></sh-checkbox>
        <div
          class="form-group alert alert-danger"
          *ngIf="
            registerResponse &&
            !registerResponse.isSuccess &&
            registerResponse.isUnauthorized
          "
        >
          {{ 'shared.general.notAuthorized' | translate }}
        </div>

        <div
          class="form-group alert alert-danger"
          *ngIf="
            registerResponse &&
            !registerResponse.isSuccess &&
            !registerResponse.isUnauthorized
          "
        >
          {{ registerResponse.errorMessage }}
        </div>
        <div class="d-flex justify-content-between mt-4">
          <sh-button link (click)="backToLogin()">
            {{ 'shared.terms.backToLogin' | translate }}
          </sh-button>
          <sh-button (click)="register()">
            {{ 'account.registration.register' | translate }}
          </sh-button>
        </div>
      </form>
    </div>

    <div *ngIf="currentForm === formType.VerifyMFA">
      <label class="control-label text-center mb-3" for="VerificationCode">
        {{ 'user.mfa.verificationCode.login' | translate }}
      </label>
      <sh-text-input [formControl]="verificationCode"></sh-text-input>
      <sh-message
        *ngIf="showVerificationError"
        [content]="'user.mfa.invalidVerificationCode' | translate"
        [severity]="'Error'"
      ></sh-message>
      <div class="d-flex justify-content-between mt-2">
        <sh-button link (click)="backToLogin()">
          {{ 'shared.terms.backToLogin' | translate }}
        </sh-button>
        <sh-button (click)="sendVerificationCode(verificationCode.value)">
          {{ 'shared.terms.verify' | translate }}
        </sh-button>
      </div>
    </div>

    <div *ngIf="currentForm === formType.SetupMFA">
      <sh-mfa-setup #mfaSetup [setupCode]="mfaSetupCode"></sh-mfa-setup>
      <sh-message
        *ngIf="showVerificationError"
        [content]="'user.mfa.invalidVerificationCode' | translate"
        [severity]="'Error'"
      ></sh-message>
      <div class="d-flex justify-content-between mt-2">
        <sh-button link (click)="backToLogin()">
          {{ 'shared.terms.backToLogin' | translate }}
        </sh-button>
        <sh-button (click)="completeMfa()">
          {{ 'shared.terms.confirm' | translate }}
        </sh-button>
      </div>
    </div>

    <sh-loading-indicator
      class="text-center"
      *ngIf="isLoading"
    ></sh-loading-indicator>
  </div>
</sh-page-layout>
