import { SeahorseRoute } from '@seahorse/common';
import { LoginPage } from './login/login.page';
import { loginGuard } from '@seahorse/auth';
import {
  ActivateAccountComponent,
  RegisterAccountThankYouComponent,
} from '@seahorse/ui';
import { InvitationComponent } from './Invitation/invitation.component';

export const authRoutes: SeahorseRoute[] = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        component: LoginPage,
        canActivate: [loginGuard],
      },
      {
        path: 'invite',
        component: InvitationComponent,
      },
      {
        path: 'register/thank-you',
        component: RegisterAccountThankYouComponent,
        canActivate: [loginGuard],
      },
      {
        path: 'activate',
        component: ActivateAccountComponent,
        canActivate: [loginGuard],
      },
    ]
  }
];
