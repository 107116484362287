import { inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type RegistrationForm = FormModel<typeof createRegistrationForm>;

export function createRegistrationForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    firstName: fb.control<string | null>(null, Validators.required),
    lastName: fb.control<string | null>(null, Validators.required),
    password: fb.control<string | null>(null, Validators.required),
    confirmPassword: fb.control<string | null>(null, Validators.required),
    companyName: fb.control<string | null>(null, Validators.required),
    emailAddress: fb.control<string | null>(null, Validators.required),
    terms: fb.nonNullable.control<boolean>(false),
  });
}
